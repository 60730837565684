
import { GET_PROCESS_STATISTICS } from '@/graphlql/queries';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DashboardCard from '../DashboardCard.vue';

@Component({
  components: {
    DashboardCard,
  },
  apollo: {
    report: {
      query: GET_PROCESS_STATISTICS,
      manual: true,
      // Disable the query
      skip() {
        return this.reportSkipQuery;
      },
      result(data) {
        if (data != null && data.loading == false) {
          if (data.data.getProcessStatistics != null && data.data.getProcessStatistics.report != null) {
            this.report = JSON.parse(data.data.getProcessStatistics.report);
            this.uptime = data.data.getProcessStatistics.uptime;
            this.reportLastUpdate = new Date().toLocaleString();
          }
        }
      },
    },
  },
})
export default class SystemStatistics extends Vue {
  @Prop({ required: false })
  content?: any;
  @Prop({ required: false })
  element?: any;

  report: any = null;
  uptime: number = 0;
  reportLastUpdate: any = null;
  reportSkipQuery: boolean = true;

  formatMemory(memory: number) {
    const k = 1024;
    let temp = memory;
    let suffix = 'byte';

    if (temp > k) {
      temp = temp / k;
      suffix = 'KB';
      if (temp > k) {
        temp = temp / k;
        suffix = 'MB';

        if (temp > k) {
          temp = temp / k;
          suffix = 'GB';
        }
      }
    }

    return Math.round(temp) + ' ' + suffix;
  }

  formatTime(time: number) {
    let temp = time;
    let temp2 = 0;
    let suffix = 'Sek';
    let suffix2 = null;

    if (temp > 60) {
      temp /= 60;
      suffix = 'Min';

      if (temp > 60) {
        temp /= 60;
        suffix = 'Std';
        temp2 = Math.round((temp - Math.floor(temp)) * 60);
        suffix2 = 'Min';

        if (temp > 24) {
          temp /= 24;
          if (Math.round(temp) == 1) {
            suffix = 'Tag';
          } else {
            suffix = 'Tage';
          }

          temp2 = Math.round((temp - Math.floor(temp)) * 24);
          suffix2 = 'Std';
        }
      }
    }
    return Math.round(temp) + ' ' + suffix + (suffix2 != null ? ' ' + temp2 + ' ' + suffix2 : '');
  }

  updateReport() {
    this.$apollo.queries.report.refetch();
    this.reportSkipQuery = false;
    setTimeout(() => {
      this.updateReport();
    }, 30000);
  }

  mounted() {
    setTimeout(() => {
      this.updateReport();
    }, 2000);
  }
}
